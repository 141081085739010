.Footer {
    font-family: 'Poppins';
    background-color: #EADFCF;
    padding: 2rem;
}

.fancylink
{
  display:inline-block;
  @apply text-asDarkOrange dark:text-asDarkOrange text-sm font-semibold
}

.fancylink::after{
  content: '';
  width: 0;
  height: 2px;
  display: block;
  transition: 300ms;
  @apply bg-asDarkOrange dark:bg-asDarkOrange
}

.fancylink:hover::after{
  width:100%;
}